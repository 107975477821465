.bet-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.bet-info-item-right {
  margin-left: 1rem;

  .pfi-description {
    text-align: left;
  }
}