.provably-fair {
  height: 23px;
  background-color: $blue-400;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}

.provably-fair-left,
.provably-fair-right {
  display: flex;
  align-items: center;
  height: 100%;

  p {
    font-family: $font-semibold;
    font-size: 10px;
    color: white;
    opacity: 0.4;
    margin-bottom: 0;

    &.bold {
      font-family: $font-bold;
      opacity: 1;
    }
  }
}
