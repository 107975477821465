.menu-item {
  display: flex;
  width: 100%;
  height: 62px;
  border-radius: 31px;
  background-color: $blue-400;
  margin-bottom: 1rem;
  padding: 1rem;

  &:hover {
    text-decoration: none;
  }
}

.menu-image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 100%;
  padding-right: 1rem;
  border-right: 1px solid $gray-100;
}

.menu-item-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  line-height: 1;
  margin-left: 1rem;
}

.menu-item-switch-holder {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.react-switch {
  background-color: #202558;
  border: 2px solid #202558;
}

.switcher-off-inactive,
.switcher-on-inactive,
.switcher-off,
.switcher-on {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 28px;
  border-radius: 12px;
  line-height: 1;
  font-family: $font-semibold;
  font-size: 14px;
  color: white;

  &.r7css {
    font-size: 16px;
  }
}

.switcher-off {
  background-color: $blue-400;
  &:focus {
    background-color: $blue-400 !important;
    box-shadow: none !important;
  }
}

.switcher-on {
  background: $yellow-100;
  color: $blue-400;

  &:hover {
    background: linear-gradient(116.37deg, #fabf41 16.45%, #c99308 96.03%);
  }
  &:focus {
    background: linear-gradient(116.37deg, #fabf41 16.45%, #ffbf1a 96.03%);
    @include boxShadow(0px 0px 15px rgba(255, 191, 26, 0.8));
  }
}

.switcher-off-inactive,
.switcher-on-inactive {
  opacity: 0.5;
  color: white !important;
  padding-top: 2px;
}

.react-switch-handle {
  height: 28px !important;
  width: 50px !important;
  background-color: $blue-400 !important;
}

.move-left {
  .react-switch-handle {
    transform: translateX(49px) !important;
  }
}
