@charset "utf-8";

// Override default variables before the import here
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import "~odometer/themes/odometer-theme-minimal.css";

// Import other scss

@import "scss/base/variables";
@import "scss/base/override";
@import "scss/base/mixins";
@import "scss/base/helpers";
@import "scss/typography";

// Components

@import "scss/components/buttons";
@import "scss/components/menu-item";
@import "scss/components/provably-fair";
@import "scss/components/how-to-play-step";
@import "scss/components/detailed-step";
@import "scss/components/divider-line";
@import "scss/components/provably-fair-item";
@import "scss/components/chat-messages";
@import "scss/components/bet-tabs";
@import "scss/components/bet-tables";
@import "scss/components/all-bets-tr";
@import "scss/components/bet-wins-item";
@import "scss/components/avatar";
@import "scss/components/bet-info-item";
@import "scss/components/jackpots-header";
@import "scss/components/pop-ups";
@import "scss/components/play-now-footer";
@import "scss/components/bet-component";
@import "scss/components/desktop-dialog";
@import "scss/components/loading-screen";
@import "scss/components/fullscreen-mode";
@import "scss/components/tournament";
@import "scss/components/retail";

// Pages
@import "scss/pages/chat-page";
@import "scss/pages/bet-info";
@import "scss/pages/play-now";

// Main Components
@import "scss/header";
@import "scss/bottom-navigator";
@import "scss/desktop";
@import "scss/general";
