.all-bets-tr {

  &.r7css {
    td {
      font-size: 12px;
    }
  }
  &:nth-child(odd) {
    background-color: #0E1340;
  }
  &:nth-child(even) {
    background-color: #070A2A;
  }
  td {
    font-family: $font-bold;
    font-size: 10px;
    line-height: 18.25px;
    color: white;
    padding: 0.5rem 0.25rem;
    text-align: center;
    width: 20%;

    &:first-child {
      width: 45%;
      text-align: left;
      padding-left: 0.5rem;
    }
    &:last-child {
      text-align: right;
      padding-right: 0.5rem;
    }
  }

  &:nth-child(even) {
    background-color: $blue-400;
  }
  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-bottom-right-radius: 5px;
      }
    }
  }
}

.bets-table-total {
  .all-bets-tr {
    td {
      &:first-child {
        width: 45%;
        text-align: left;
      }
      &:nth-last-child(2) {
        text-align: right;
      }
      &:last-child {
        width: 20%;
        text-align: right;

        &.share {
          width: 10%;
        }
      }
    }
  }
}

.my-bets-table {
  .all-bets-tr {
    td {
      &:first-child {
        width: 25%;
        text-align: left;
      }
      &:nth-last-child(2) {
        text-align: right;
      }
      &:last-child {
        text-align: center;
      }
    }
  }
}

.bonusing-history-table {
  .all-bets-tr {
    td {
      &:nth-last-child(2) {
        text-align: center;
      }
    }
  }
}

.my-bets-share {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .table-share {
    &:first-child {
      margin-right: 0.375rem;
    }
  }
}

.all-bets-td-holder {
  display: flex;
  align-items: center;
}

.all-bets-tr-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  img {
    width: 100%;
  }
}

.all-bets-tr-username {
  position: relative;
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0.375rem;
}

.all-bets-tr-flag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  img {
    border-radius: 2px;
  }
}

.all-bets-tr-coefficient {
  width: fit-content;
  padding: 0 0.25rem;
  border-radius: 5px;

  &.win {
    width: auto;
    background-color: $green-100;
    color: $green-200;
  }
  &.win-small {
    background-color: $blue-700;
    color: $blue-800;
  }
  &.win-big {
    background-color: #F7FD04;
    color: #141945;
  }
  &.win-legendary {
    background-color: #db1f35;
    color: #f8ced3;
  }
}

.all-bets-tr-coefficient-retail {
  &.light-blue {
    background-color: #4472C4;
    color: white;
  }
  &.blue {
    background-color: #252b63;
    color: #3BC4F2;
  }
  &.green {
    background-color: #a7e623;
    color: #466408;
  }
  &.yellow {
    background-color: #f7fd04;
    color: #070a2a;
  }
  &.orange {
    background-color: #ffbf00;
    color: #221a00;
  }
  &.red {
    background-color: #db1f35;
    color: #f8ced3;
  }
}

.table-share {
  position: relative;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;

  &.disabled, &:disabled {
    opacity: .4;
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  button {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
