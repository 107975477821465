@mixin transition($value) {
  -webkit-transition: $value; /* Safari/Chrome, other WebKit */
  -moz-transition: $value; /* Firefox, other Gecko */
  -o-transition: $value; /* Opera/IE 8+ */
  transition: $value;
}

@mixin transform($value) {
  -webkit-transform: $value; /* Safari/Chrome, other WebKit */
  -moz-transform: $value; /* Firefox, other Gecko */
  -o-transform: $value; /* Opera/IE 8+ */
  transform: $value;
}

@mixin boxShadow($value) {
  -webkit-box-shadow: $value; /* Safari/Chrome, other WebKit */
  -moz-box-shadow: $value; /* Firefox, other Gecko */
  -o-box-shadow: $value; /* Opera/IE 8+ */
  box-shadow: $value;
}

@mixin animationDelay($value) {
  -webkit-animation-delay: $value; /* Safari/Chrome, other WebKit */
  -moz-animation-delay: $value; /* Firefox, other Gecko */
  -o-animation-delay: $value; /* Opera/IE 8+ */
  animation-delay: $value;
}

@mixin boxSizing($value) {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

@mixin dropShadow($value) {
  -webkit-filter: $value; /* Safari/Chrome, other WebKit */
  -moz-filter: $value; /* Firefox, other Gecko */
  -o-filter: $value; /* Opera/IE 8+ */
  filter: $value;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin gradientAnimation($start, $end, $transTime, $bRadius) {
  background-size: 100%;
  background: -webkit-$start;
  background: -moz-$start;
  background: -o-$start;
  background: $start;
  position: relative;
  z-index: 1;
  &:before {
    background: -webkit-$end;
    background: -moz-$end;
    background: -o-$end;
    background: $end;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -1;
    transition: opacity $transTime;
    border-radius: $bRadius;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

@mixin gradientAnimationBetButton($start, $end, $transTime, $bRadius) {
  background-size: 100%;
  background: -webkit-$start;
  background: -moz-$start;
  background: -o-$start;
  background: $start;
  position: relative;
  z-index: 1;
  &:before {
    background: -webkit-$end;
    background: -moz-$end;
    background: -o-$end;
    background: $end;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -1;
    transition: opacity $transTime;
    border-radius: $bRadius;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}
