.play-now-page-holder {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 0 !important;
  padding-bottom: 74px !important;

  @media (min-width: 1024px) {
    padding-bottom: 0 !important;
  }
}
