.pop-up {
  .modal-dialog {
    padding-top: 2rem;
  }

  &.help {
    .modal-dialog {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }

  .modal-body {
    padding-bottom: 2rem;
  }
}

.modal-backdrop {
  background: rgba(3, 5, 32, 0.8);

  &.show {
    opacity: 1;
  }
}

.astronaut-pop-up-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.help-offer-body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem .5rem;
  background-color: $blue-400;
  border-radius: 1rem;
}

.pop-up-message-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1rem 2rem;
  padding: 1.5rem 2rem 3rem 2rem;
  background-color: $blue-400;
  border-radius: 1rem;

  p.pop-up-message-description {
    font-family: $font-semibold;
    font-size: 16px;
    color: white;
    text-align: center;
  }
}

.pop-up-close {
  position: relative;
  margin-top: -34px;

  .primary-button {
    margin-bottom: 1rem;
  }
}

.pupb-vissible {
  position: relative;
  z-index: 1;
  font-family: $font-bold;
  font-size: 32px;
  color: white;
  -webkit-text-stroke: 2px $yellow-100;
  text-shadow: 0px 6.97436px 17.4359px #030520;

  span {
    font-size: 24px;
  }
}

.pupb-hidden {
  position: absolute;
  top: 0;
  font-family: $font-bold;
  font-size: 32px;
  color: white;
  -webkit-text-stroke: 13px $yellow-100;
  text-shadow: 0px 6.97436px 17.4359px #030520;

  span {
    font-size: 24px;
  }
}

.pupt-vissible {
  position: relative;
  z-index: 1;
  font-family: $font-bold;
  font-size: 36px;
  color: white;
  -webkit-text-stroke: 2px $blue-800;
  // filter: drop-shadow(0px 6.97436px 17.4359px #030520);
  text-shadow: 0px 4px 10px #030520;
  text-align: center;
}

.pupt-hidden {
  position: absolute;
  top: 0;
  font-family: $font-bold;
  font-size: 36px;
  color: white;
  -webkit-text-stroke: 7px $blue-800;
  // filter: drop-shadow(0px 6.97436px 17.4359px #030520);
  text-shadow: 0px 4px 10px #030520;
  text-align: center;
}

.vip-popup {
  z-index: 1162 !important;

  .pupt-vissible {
    -webkit-text-stroke: 2px $yellow-100;
  }

  .pupt-hidden {
    -webkit-text-stroke: 7px $yellow-100;
  }

  .astronaut-pop-up-body {
    background-color: rgba(7, 10, 42, 1);
    border-radius: 12px;
  }
}

.pop-up-promo-bonus {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: .5rem;
}

.pop-up-promo-bonus-holder {
  top: 0;
}

.pop-up-promo-text {
  position: relative;
  width: 260px;
  display: flex;
  justify-content: center;

  &.help {
    width: 320px;
  }

  &.pop-up-promo-text-vip {
    width: 280px;

    @media (min-width: 1024px) {
      width: 350px;
    }
  }
}

.pop-up-promo-text-holder {
  position: relative;
}

.alien-saucer-text {
  .pupt-vissible {
    -webkit-text-stroke: 2px #a7e623;
  }

  .pupt-hidden {
    -webkit-text-stroke: 7px #a7e623;
  }
}

.on-snap-pop-up {
  position: absolute;
  // top: 50%;
  // margin-top: -140px;
  top: 48px;
  left: 1rem;
  right: 1rem;
  bottom: 0;
  height: 280px;
  background-color: rgba(7, 10, 43, 0.9);
  border-radius: 5px;
  z-index: 1062;

  /* z-index: 1041; */
  .modal-dialog {
    height: 100%;
    padding: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-bottom: 74px;
  }

  .modal-content {
    position: static;
  }
}

.new-popup {
  position: absolute;
  top: 48px;
  left: 15px;
  right: 15px;
  /* bottom: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(7, 10, 42, 0.8);
  /* z-index: 1041; */
  border-radius: 5px;

  .pop-up-message-box {
    margin: 1rem 1rem;
    padding: 0 0.5rem;
    background-color: transparent;
  }

  .pop-up-promo-text {
    width: 100%;
  }

  .pupt-vissible,
  .pupt-hidden {
    margin-top: 1rem;
    font-size: 26px;
  }

  .pupt-vissible {
    -webkit-text-stroke: 1px #a7e623;
  }

  .blue-text-body {
    .pupt-vissible {
      -webkit-text-stroke: 1px #3BC4F2;
    }
  }

  .pupb-vissible,
  .pupb-hidden {
    font-size: 26px;
  }

  .pupb-vissible {
    -webkit-text-stroke: 1px $yellow-100;
  }
}

.new-popup-body {
  flex-direction: row;

  @media (max-width: 1024px) and (max-height: 767px) {
    zoom: .8;
  }

  @media (max-width: 359px) {
    zoom: .8;
  }
}

.new-popup-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-popup-img {
  height: 40px;
  position: absolute;
  top: -8px;
  right: -20px;
}

.new-pop-up-close {
  display: flex;
  margin-top: 0;
  margin-bottom: .5rem;

  .primary-button,
  .secondary-button {
    margin-bottom: 0;
    margin: 0 .25rem;
  }
}

.oh-snap-pop-up-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.oh-snap-pop-up-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-family: $font-bold;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: -0.75rem;
  }

  h2 {
    font-family: $font-bold;
    font-size: 64px;
    color: white;
    text-shadow: 0px 0px 8px rgba(239, 35, 35, 0.9);
  }

  h5 {
    font-family: $font-semibold;
    font-size: 18px;
    color: white;
  }
}

.oh-snap-timer {
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.oh-snap-timer-body {
  padding: 0.5rem 1rem;
  min-width: 100%;
  height: 40px;
  // border-radius: 20px;
  background: #252b63;
  @include boxShadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21.29px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  p {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: $font-semibold;
    color: white;
  }
}

.over-popup,
.astronaut-popup {
  z-index: 1061;
}

.advanced-autoplay-promo-popup {
  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #070a2a;
  }

  .astronaut-pop-up-body {
    max-width: 350px;
    pointer-events: none;

    @media (min-width: 1024px) {
      max-width: initial;
    }

    .aap-image {
      max-width: 100%;
    }

    .aap-double-input-holder {
      width: 102%;
    }
  }

  .pop-up-promo-text-holder {
    margin-top: -10px;

    .pupt-vissible,
    .pupt-hidden {
      font-size: 28px;
    }
  }

}

.bet-insurance-promo {
  .bet-insurance-promo-image {
    margin-left: 2rem;
    margin-bottom: 2rem;
  }

  .pop-up-message-box {
    padding: 1rem;
  }

  .pop-up-message-description {
    i {
      font-size: 14px;
    }

  }

  .pop-up-close {
    margin-top: 0;

    .primary-button {
      margin-bottom: 0;
    }
  }
}

.tournament-winner-popup {
  .modal-body {
    background-color: rgb(7, 10, 42);
    padding-bottom: 3rem;
  }
}

.over-popup-backdrop {
  z-index: 1051;

  &.help {
    z-index: 1071;
  }
}

.over-popup {
  &.help {
    z-index: 1081;
  }
}


.jackpot-popup-image {
  margin-top: -79px;
}

.jackpot-popup-text {
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  margin-top: 0.5rem;
}

.jackpot-promo-text {
  position: relative;
  width: 260px;
  height: 50px;
  display: flex;
  justify-content: center;
}

.pop-up-promo-win {
  position: relative;
  width: 120%;
  min-height: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1.5rem;

  .pop-up-promo-bonus-holder {
    position: relative;
  }

  .pupb-vissible {
    text-align: center;
    font-size: 44px;
  }

  .pupb-hidden {
    text-align: center;
    font-size: 44px;
  }

  &.boosterpot {
    .pupb-vissible {
      -webkit-text-stroke: 1px #98aabe;
    }

    .pupb-hidden {
      -webkit-text-stroke: 13px #98aabe;
    }
  }

  &.rocketpot {
    .pupb-vissible {
      -webkit-text-stroke: 1px #3081e5;
    }

    .pupb-hidden {
      -webkit-text-stroke: 13px #3081e5;
    }
  }

  &.rocketpot {
    .pupb-vissible {
      -webkit-text-stroke: 1px #3081e5;
    }

    .pupb-hidden {
      -webkit-text-stroke: 13px #3081e5;
    }
  }

  &.jacketpot {
    .pupb-vissible {
      -webkit-text-stroke: 1px #9665e5;
    }

    .pupb-hidden {
      -webkit-text-stroke: 13px #9665e5;
    }
  }
}

.jackpot-popup-close {
  .primary-button {
    position: relative;
    width: 150px;
    z-index: 10;
  }
}

.jackpot-coins {
  margin-right: 0.5rem;
  width: 69px;
  height: 61px;
}

.boosterpot-text {
  .pupt-vissible {
    -webkit-text-stroke: 1px #98aabe;
  }

  .pupt-hidden {
    -webkit-text-stroke: 7px #98aabe;
  }
}

.rocketpot-text {
  .pupt-vissible {
    -webkit-text-stroke: 1px #3081e5;
  }

  .pupt-hidden {
    -webkit-text-stroke: 7px #3081e5;
  }
}

.jacketpot-text {
  .pupt-vissible {
    -webkit-text-stroke: 1px #9665e5;
  }

  .pupt-hidden {
    -webkit-text-stroke: 7px #9665e5;
  }
}

.jacketpot-top {
  position: absolute;
  top: -110px;
  opacity: 0.2;
}

.jacketpot-bottom {
  position: absolute;
  bottom: -173px;
  opacity: 0.2;
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.floating-slow {
  animation-name: floating-slow;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.floating-reverse {
  animation-name: floating-reverse;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.rotating {
  animation-name: rotating;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.rotating-reverse {
  animation-name: rotating-reverse;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.rain-second {
  animation: rain-second 1;
  -moz-animation: rain-second 1.4s;
  /* Firefox */
  -webkit-animation: rain-second 1.4s;
  /* Safari and Chrome */
  -o-animation: rain-second 1.4s;
  /* Opera */
  // webkit-animation-fill-mode: forwards;
  // animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0.4s;
}

.rain-third {
  animation: rain-third 1.2s;
  -moz-animation: rain-third 1.6s;
  /* Firefox */
  -webkit-animation: rain-third 1.6s;
  /* Safari and Chrome */
  -o-animation: rain-third 1.6s;
  /* Opera */
  // webkit-animation-fill-mode: forwards;
  // animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0.2s;
}

.rain-fourth {
  animation: rain-third 1.4s;
  -moz-animation: rain-third 1.8s;
  /* Firefox */
  -webkit-animation: rain-third 1.8s;
  /* Safari and Chrome */
  -o-animation: rain-third 1.8s;
  /* Opera */
  // webkit-animation-fill-mode: forwards;
  // animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    @include transform(translate(0, 0px));
  }

  50% {
    @include transform(translate(0, 5px));
  }

  100% {
    @include transform(translate(0, -0px));
  }
}

@keyframes floating-slow {
  0% {
    @include transform(translate(0, 0px));
  }

  50% {
    @include transform(translate(2px, 7px));
  }

  100% {
    @include transform(translate(0, -0px));
  }
}

@keyframes floating-reverse {
  0% {
    @include transform(translate(0, -0px));
  }

  50% {
    @include transform(translate(0, 5px));
  }

  100% {
    @include transform(translate(0, 0px));
  }
}

@keyframes rotating {
  0% {
    @include transform(rotate(0deg));
  }

  100% {
    @include transform(rotate(360deg));
  }
}

@keyframes rotating-reverse {
  0% {
    @include transform(rotate(0deg));
  }

  100% {
    @include transform(rotate(-360deg));
  }
}


@keyframes rain-bottom {
  from {
    opacity: 1;
  }

  to {
    top: 768px;
    opacity: 0;
  }
}

@keyframes rain-left {
  from {
    opacity: 1;
  }

  to {
    top: 768px;
    left: 0;
    opacity: 0;
  }
}

@keyframes rain-middle {
  from {
    opacity: 1;
  }

  to {
    top: 768px;
    left: 320px;
    opacity: 0;
  }
}

@keyframes rain-right {
  from {
    opacity: 1;
  }

  to {
    top: 768px;
    left: 640px;
    opacity: 0;
  }
}

@keyframes rain-140 {
  from {
    opacity: 1;
  }

  to {
    top: 768px;
    left: 140px;
    opacity: 0;
  }
}

@keyframes rain-490 {
  from {
    opacity: 1;
  }

  to {
    top: 768px;
    left: 490px;
    opacity: 0;
  }
}

// float and rotating coins

.floating-coins-holder {
  position: fixed;
  top: 65px;
  left: 50%;
  margin-left: -320px;
  z-index: 1;
}

.floating-coin {
  position: absolute;
  z-index: 999;
  //animation: rain;
  //animation-duration: 1.2s;
  //animation-iteration-count: infinite !important;
  //animation-timing-function: ease-in-out !important;
}

.floating-coin-1 {
  animation: rain-middle !important;
}

.floating-coin-2 {
  animation: rain-left !important;
}

.floating-coin-3 {
  animation: rain-right !important;
}

.floating-coin-4 {
  animation: rain !important;
}

.floating-set {
  position: relative;
}

.floating-set-1 {
  .floating-coin {
    animation: rain;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    left: 0;

    &:nth-child(1) {
      top: -50px;
    }

    &:nth-child(2) {
      top: -100px;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      top: -150px;
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      top: -200px;
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      top: -250px;
      animation-delay: 0.8s;
    }
  }
}

.floating-set-2 {
  .floating-coin {
    animation: rain;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    left: 70px;

    &:nth-child(1) {
      top: -70px;
    }

    &:nth-child(2) {
      top: -120px;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      top: -180px;
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      top: -220px;
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      top: -280px;
      animation-delay: 0.8s;
    }
  }
}

.floating-set-3 {
  .floating-coin {
    animation: rain-140;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    left: 140px;

    &:nth-child(1) {
      top: -100px;
    }

    &:nth-child(2) {
      top: -150px;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      top: -200px;
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      top: -250px;
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      top: -300px;
      animation-delay: 0.8s;
    }
  }
}

.floating-set-4 {
  .floating-coin {
    animation: rain-140;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    left: 210px;

    &:nth-child(1) {
      top: -50px;
    }

    &:nth-child(2) {
      top: -100px;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      top: -150px;
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      top: -200px;
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      top: -250px;
      animation-delay: 0.8s;
    }
  }
}

.floating-set-5 {
  .floating-coin {
    animation: rain;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    left: 280px;

    &:nth-child(1) {
      top: -90px;
    }

    &:nth-child(2) {
      top: -140px;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      top: -190px;
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      top: -240px;
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      top: -290px;
      animation-delay: 0.8s;
    }
  }
}

.floating-set-6 {
  .floating-coin {
    animation: rain;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    left: 350px;

    &:nth-child(1) {
      top: -90px;
    }

    &:nth-child(2) {
      top: -140px;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      top: -190px;
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      top: -240px;
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      top: -290px;
      animation-delay: 0.8s;
    }
  }
}

.floating-set-7 {
  .floating-coin {
    animation: rain-490;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    left: 420px;

    &:nth-child(1) {
      top: -100px;
    }

    &:nth-child(2) {
      top: -150px;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      top: -200px;
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      top: -250px;
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      top: -300px;
      animation-delay: 0.8s;
    }
  }
}

.floating-set-8 {
  .floating-coin {
    animation: rain-490;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    left: 490px;

    &:nth-child(1) {
      top: -50px;
    }

    &:nth-child(2) {
      top: -100px;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      top: -150px;
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      top: -200px;
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      top: -250px;
      animation-delay: 0.8s;
    }
  }
}

.floating-set-9 {
  .floating-coin {
    animation: rain;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    left: 560px;

    &:nth-child(1) {
      top: -70px;
    }

    &:nth-child(2) {
      top: -120px;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      top: -180px;
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      top: -220px;
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      top: -280px;
      animation-delay: 0.8s;
    }
  }
}

.floating-set-10 {
  .floating-coin {
    animation: rain;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    left: 610px;

    &:nth-child(1) {
      top: -50px;
    }

    &:nth-child(2) {
      top: -100px;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      top: -150px;
      animation-delay: 0.4s;
    }

    &:nth-child(4) {
      top: -200px;
      animation-delay: 0.6s;
    }

    &:nth-child(5) {
      top: -250px;
      animation-delay: 0.8s;
    }
  }
}

// floating circles

.floating-circle-holder {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -150px;
  width: 300px;
  height: 300px;
}

.floating-circle-green {
  position: absolute;
  z-index: 1;
  top: 50px;
  left: 50px;
}

.floating-circle-blue {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 15px;
}

.floating-circle-purple {
  position: absolute;
  z-index: 1;
  top: -15px;
  left: 50px;
}

.floating-circle-orange {
  position: absolute;
  z-index: 1;
  top: 45px;
  right: 50px;
}

.floating-circle-purple-second {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 25px;
}

.floating-circle-green-second {
  position: absolute;
  z-index: 1;
  top: -40px;
  right: 35px;
}

.jackpot-modal {
  .modal-dialog {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .pop-up-message-box {
    background: rgba(7, 10, 43, 0.8);
  }
}

.over-jackpot-backdrop {
  background: rgba(3, 5, 32, 0.5);
  backdrop-filter: blur(4px);
}

.advanced-autoplay-popup {

  // .aap-loader {
  //   height: 1px;
  //   background-color: white;
  //   animation: aap-animate 5s ease forwards alternate;
  //   animation-iteration-count: 1;
  // }

  .modal-body {
    padding-bottom: 1rem;
  }

  .astronaut-pop-up-body {
    align-items: inherit;
  }

  .modal-content {
    background-color: #070a2a;
  }

  .modal-header {
    justify-content: center;
  }

  h1 {
    font-family: $font-bold;
    font-size: 22px;
    color: white;
    // color: rgba(255, 255, 255, 0.5);
  }
}

@keyframes aap-animate {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.aap-input-holder {
  width: 100%;
  margin-bottom: .5rem;

  .play-now-number-input {
    input {
      font-size: 18px;
    }
  }

  &.half-width {
    width: 50%;
  }

  .play-now-number-input-increse,
  .play-now-number-input-decrese {
    width: 20px;
    height: 20px;

    .minus {
      &::after {
        width: 10px;
        margin-left: -5px;
      }
    }

    .plus {
      &::before {
        height: 10px;
        margin-top: -5px;
      }

      &::after {
        width: 10px;
        margin-left: -5px;
      }
    }
  }

  .auto-cashout-holder {
    margin-right: 0;
    margin-bottom: 2px;
  }

  .play-now-number-input,
  .auto-cashout {
    height: 38px;
  }

  .auto-cashout {
    background-color: rgba(37, 43, 99, 0.8);

    &.warning {
      border: dotted darkred;
    }
  }
}

.aap-label {
  font-family: $font-bold;
  font-size: 12px;
  line-height: 18.25px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0.25rem;
  text-transform: uppercase;

  &.warning {
    font-family: $font-semibold;
    text-align: center;
    margin-bottom: 1rem;
    color: red;
  }

  &.r7css {
    font-size: 14px;
  }
}

.aap-double-input-holder {
  display: flex;
  margin-right: -.25rem;
  margin-left: -.25rem;
  align-items: flex-end;

  .aap-input-holder {
    width: 50%;
    padding: 0 0.25rem;
  }
}

.aap-input {
  height: 38px;
  width: 100%;
  background-color: rgba(37, 43, 99, 0.8);
  border-radius: 5px;
  border: none;
  font-family: $font-regular;
  font-size: 16px;
  color: white;
  padding: 0.5rem;

  &:focus {
    outline: none;
  }

  @media (max-width: 1024px) and (max-height: 767px) {
    font-size: 12px;
  }

  @media (max-width: 359px) {
    font-size: 12px;
  }
}

.aap-input-icon-right {
  position: relative;

  .aap-input {
    padding-right: 40px;
  }
}

.aap-input-icon-left {
  position: relative;

  .aap-input {
    padding-left: 40px;
  }
}

.with-switch {
  .aap-switch {
    position: absolute;
    right: 10px;
    top: 2px;

    .react-switch-handle {
      width: 70px !important;
      background: #ffbf19 !important;

      .switcher-on,
      .switcher-off {
        background: #ffbf19 !important;
        color: #070a2a;
      }
    }

    .react-switch-bg>div {
      width: 70px !important;
    }

    .move-left .react-switch-handle {
      transform: translateX(70px) !important;
    }

    .switcher-off-inactive,
    .switcher-on-inactive,
    .switcher-off,
    .switcher-on {
      width: 70px !important;
    }
  }
}

.aap-input-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  margin-top: -10px;

  &.position-right {
    right: 10px;
    color: white;
    margin-top: -13px;
  }

  &.position-left {
    left: 10px;
  }
}

.aap-input-info {
  width: 20px;
  height: 20px;
  margin-left: .5rem;
  margin-bottom: 2px;
}

.highscores-table-wrapper {
  border: 2px solid #ffbf19;
  border-radius: 5px;
}

.high-score-modal-backdrop {
  background-color: transparent;
}

.highscores-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: #070a2a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.highscore-table {
  .table-header {
    th {
      &:first-child {
        border-top-left-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0;
      }
    }
  }
}

.high-score-modal {
  position: relative;
  width: 100%;
  max-width: 468px;
  margin: 0 auto;
  margin-bottom: 60px;

  &.infinite {
    margin-top: -12px;
    color: #ffffff;
    font-family: $font-regular;
    right: 18px;
    width: 90px;
    text-align: right;
  }
}

.blured-popup {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}