.chat-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding-bottom: 72px;
  position: fixed;
  bottom: 72px;
  left: 0;
  right: 0;
  padding-top: 140px;

  @media (min-width: 1024px) {
    position: relative;
    bottom: inherit;
    left: inherit;right: inherit;
    padding-top: 0;
  }
}

.chat-messages-holder {
  /* overflow: auto;  */
  padding-bottom: 1rem;
  min-height: 100%;
}

.chat-holder {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 72px;
  padding: 0 1rem;
}

.chat-page-input-holder {
  position: relative;
  flex: 1;
  margin-right: 1rem;
}

.chat-page-input {
  width: 100%;
  height: 48px;
  background-color: $blue-700;
  font-family: $font-regular;
  font-size: 16px;
  color: white;
  padding: 0.75rem 1rem;
  padding-right: 3.25rem;
  border-radius: 24px;
  border: none;

  &:focus {
    outline: none;
  }
}

.char-counter-holder {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 48px;
  width: 48px;
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  .counter-zero {
    color: $red-100;
  }
}

.chat-spam-filter {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  background-color: $blue-400;
  z-index: 12;
  flex-direction: column;
  padding: 1rem;

  &.active {
    display: flex;
  }

  p {
    font-family: $font-semibold;
    font-size: 14px;
    line-height: 21.29px;
    color: white;
    margin-bottom: 0.5rem;

    span {
      color: $blue-800;
    }
  } 
}

.progress {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: $blue-900;
}

.progress-bar {
  background: linear-gradient(
    90deg,
    #3bc4f2 74.73%,
    rgba(59, 196, 242, 0) 98.56%
  );
}