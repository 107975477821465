.my-message-holder {
  display: flex;
  justify-content: flex-end;
}

.current-user-message {
  .my-message-holder {
    justify-content: flex-end;
  }

  .my-message,
  .my-message-shared-top {
    background: linear-gradient(116.41deg, #d5d7ea 33.41%, #633afa 212.04%);
    transform: matrix(-1, 0, 0, 1, 0, 0);

    p,
    .my-meteor-shower-message {
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }

  .my-meteor-shower-message {
    p {
      transform: none;
    }
  }

  .my-message-number-of-likes {
    margin-right: -10px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    padding-top: 1px;
    padding-right: 5px;
    margin-top: 4px;
  }

  .my-message-number-of-likes-clicked-holder {
    margin-right: 0.5rem;
  }

  .mmsb-info {
    align-items: flex-end;
  }

  .mmsb-right {
    padding-left: 0;
  }

  .mmsb-left {
    padding-right: 1rem;
  }

  .my-message-gif {
    border-radius: 16px;
    border-top-right-radius: 4px;

    img {
      border-radius: 16px;
      border-top-right-radius: 4px;
    }
  }

  .message-avatar-holder {
    justify-content: flex-end;
  }

  .provably-fair-shared {
    left: -40px !important;
  }
}

.user-message {
  .my-message-holder {
    flex-direction: row-reverse;
  }

  .my-message,
  .my-message-shared-top {
    margin-right: 0.5rem;
    background: linear-gradient(116.41deg, #d5d7ea 33.41%, #ffbf1a 212.04%);

    &.special-1 {
      background: linear-gradient(116.41deg, #db1f35 33.41%, #ffbf1a 212.04%);
    }

    &.special-2 {
      background: linear-gradient(116.41deg, #e9f500 33.41%, #ffbf1a 212.04%);
    }

    &.special-3 {
      background: linear-gradient(116.41deg, #075ba1 33.41%, #ffbf1a 212.04%);
    }
  }

  .my-message-gif {
    margin-right: 0.5rem;
  }

  .my-message-shared {
    margin-right: 0.5rem;
  }

  .message-time {
    justify-content: flex-start;
    margin-bottom: 0.25rem;
  }

  .my-message-like-holder {
    flex-direction: row-reverse;
  }

  .my-message-number-of-likes {
    margin-left: -10px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    padding-top: 1px;
    padding-left: 10px;
    margin-top: 4px;
  }

  .provably-fair-shared {
    right: -40px !important;
  }
}

.my-message {
  display: flex;
  margin-bottom: 1rem;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 16px;
  border-top-left-radius: 4px;
  max-width: 260px;

  &.my-message-shower {
    max-width: 260px;
    min-width: 260px;

    @media (min-width: 1024px) {
      max-width: 200px;
      min-width: 200px
    }

    @media (min-width: 1200px) {
      max-width: 260px;
      min-width: 260px
    }
  }

  @media (min-width: 1024px) {
    max-width: 200px;
  }

  @media (min-width: 1200px) {
    max-width: 260px;
  }

  p,
  .my-meteor-shower-message {
    font-family: $font-regular;
    font-size: 16px;
    color: $blue-400;
    line-height: 24.34px;
    word-break: break-word;
  }

  &.special-1,
  &.special-3 {
    p {
      color: white;
    }
  }
}

.flag-bot {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-semibold;
  font-size: 10px;
  width: 21px;
  height: 15px;
  background-color: #252b63;
  color: #3bc4f2;
  border-radius: 1px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.my-message-shared {
  width: 260px;
  margin-bottom: 1rem;
  position: relative;
}

.provably-fair-shared {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background: linear-gradient(116.37deg, #fabf41 16.45%, #ffbf1a 96.03%);
  border-radius: 32px;
  position: absolute !important;
  bottom: 0;
  border: none;

  @include boxShadow(0px 0px 10px rgba(0, 0, 0, 0.4));
  @include gradientAnimation($yellow-100-gradient,
    $yellow-200-gradient,
    0.6s,
    18px);

  &:disabled {
    opacity: 0.6;
    @include gradientAnimation($yellow-100-gradient,
      $yellow-100-gradient,
      0s,
      18px);
  }

  &:focus {
    @include boxShadow(0px 0px 15px rgba(255, 191, 26, 0.8));
  }

  &:active {
    @include transition(0.1s ease-in-out all);
    background-color: $yellow-300-gradient;
  }
}

.my-message-shared-top {
  display: flex;
  width: 100%;
  padding: 0.75rem 1rem;
  border-top-right-radius: 16px;
  border-top-left-radius: 4px;

  p {
    font-family: $font-regular;
    font-size: 16px;
    color: $blue-400;
    line-height: 24.34px;
  }
}

.mmsb-left,
.mmsb-right {
  width: 50%;
}

.mmsb-left {
  border-right: 1px dashed $blue-100;
}

.mmsb-right {
  padding-left: 1rem;
}

.my-message-shared-bottom {
  display: flex;
  width: 100%;
  padding: 0.75rem 1rem;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: $blue-700;
}

.mmsb-info {
  display: flex;
  flex-direction: column;
}

.mmsb-info-divider {
  height: 0.5rem;
}

.mmsb-info-title {
  font-family: $font-bold;
  font-size: 10px;
  line-height: 1.2;
  color: white;
  opacity: 0.6;
  text-transform: uppercase;
  margin-bottom: 2px;

  &.r7css {
    font-size: 12px;
  }
}

.mmsb-info-content {
  font-family: $font-bold;
  font-size: 14px;
  line-height: 21.29px;
  color: white;

  &.win {
    width: fit-content;
    background-color: $green-100;
    color: $green-200;
    padding: 0 0.25rem;
    border-radius: 5px;
  }

  &.win-small {
    width: fit-content;
    background-color: $blue-700;
    color: $blue-800;
    padding: 0 0.25rem;
    border-radius: 5px;
  }

  &.win-big {
    width: fit-content;
    background-color: #f7fd04;
    color: #141945;
    padding: 0 0.25rem;
    border-radius: 5px;
  }

  &.win-legendary {
    width: fit-content;
    background-color: #db1f35;
    color: #f8ced3;
    padding: 0 0.25rem;
    border-radius: 5px;
  }
}

.mmsb-info-content-round-id,
.mmsb-info-content-round-id-title {
  padding: 0 2px;

  @media (min-width: 1200px) {
    padding: 0 1px;
  }
}

.my-message-gif {
  display: flex;
  margin-bottom: 1rem;
  width: fit-content;
  border-radius: 16px;
  border-top-left-radius: 4px;
  max-width: 260px;
  max-height: 260px;

  @media (min-width: 1024px) {
    max-width: 200px;
    max-height: 200px;
  }

  @media (min-width: 1200px) {
    max-width: 260px;
    max-height: 260px;
  }

  img {
    width: 100%;
    border-radius: 16px;
    border-top-left-radius: 4px;
    object-fit: cover;
  }
}

.message-time {
  display: flex;
  justify-content: flex-end;
  color: white;
  opacity: 0.4;
  font-family: $font-regular;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0.25rem;
}

.my-message-like {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: $blue-1000;
  padding-bottom: 5px;
  border: 1px solid transparent;
  @include transition(0.3s ease-in-out all);

  &:focus {
    border-color: $yellow-100;
  }

  &:active {
    background-color: $yellow-100;
  }

  &.liked {
    &:active {
      background-color: $blue-1000;
    }
  }
}

.chat-page-players-holder {
  position: fixed;
  top: 74px;
  min-width: 112px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  @media (min-width: 1024px) {
    display: none;
  }
}

.chat-page-players-line {
  width: 2px;
  height: 4px;
  background: rgba(7, 10, 43, 0.9);
}

.chat-page-players {
  padding: 0.5rem 1rem;
  min-width: 112px;
  height: 40px;
  border-radius: 20px;
  background: rgba(7, 10, 43, 0.9);
  @include boxShadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21.29px;

  p {
    font-size: 14px;
    font-family: $font-bold;
    color: white;
  }

  span {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #a7e623;
    margin: 0 4px;
    margin-bottom: -1px;
  }
}

.my-message-like-holder {
  display: flex;
}

.my-message-number-of-likes-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $font-regular;
  font-size: 14px;
  line-height: 21px;
  color: white;

  span {
    margin-top: 4px;
  }
}

.my-message-number-of-likes-clicked-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $font-regular;
  font-size: 14px;
  line-height: 21px;
  color: white;
}

.my-message-number-of-likes {
  display: flex;
  justify-content: center;
  width: 38px;
  height: 24px;
  background-color: $blue-700;
}

.message-avatar-holder {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.message-avatar {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  margin-right: 0.5rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

  .rocketman-img {
    height: 70%;
    width: auto;
  }
}

.message-username {
  font-family: $font-bold;
  color: white;
  font-size: 14px;
  line-height: 21.29px;
  margin-right: 0.5rem;
  padding-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-to-user {
  font-family: $font-bold;
  color: $yellow-100;
  font-size: 14px;
  line-height: 21.29px;
  margin-left: 0.5rem;
}

.meteor-shower-title-header,
.meteor-shower-title-footer {
  font-family: $font-bold !important;
  font-size: 14px !important;
  line-height: 1.2 !important;
}

.meteor-shower-title-footer {
  display: flex;
  justify-content: space-between;

  span {
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
}