.top-wins-item-holder {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 5px;
  background-color: $blue-400;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.top-wins-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.top-wins-header-left,
.top-wins-heaeder-right {
  display: flex;
  align-items: center;
  z-index: 0;
}

.top-wins-heaeder-right,
.biggest-odds-info {
  .table-share {
    margin-left: 0.5rem;
  }
}

.top-wins-footer {
  display: flex;
  padding: 0.5rem 0.5rem 0.5rem 34px;

  .mmsb-info {
    width: 25%;

    &:last-child {
      .mmsb-info-title,
      .mmsb-info-content {
        text-align: right;
      }
    }
  }

  .mmsb-info-content {
    font-size: 12px;
    line-height: 1.2;
  }

  &.without-top-wins {
    .mmsb-info {
      width: 33.33%;
    }
  }
}

.top-wins-username {
  margin: 0 0.375rem;
  font-family: $font-bold;
  font-size: 12px;
  line-height: 18.25px;
  color: white;
  flex: 1;
  position: relative;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-wins-separator-line {
  height: 1px;
  background-color: $gray-100;
  opacity: 0.4;
  margin-left: 34px;
}

.biggest-odds-item {
  display: flex;
  height: 52px;
  border-radius: 5px;
  background-color: $blue-400;
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.biggest-odds-info {
  display: flex;
  align-items: center;
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .mmsb-info-content {
    font-size: 12px;
    padding: 0.125rem 0.5rem;
    border-radius: 5px;

    &.win {
      width: auto;
      background-color: $green-100;
      color: $green-200;
    }

    &.win-small {
      background-color: $blue-700;
      color: $blue-800;
    }

    &.win-big {
      background-color: #F7FD04;
      color: #141945;
    }

    &.win-legendary {
      background-color: #db1f35;
      color: #f8ced3;
    }
  }

  &:last-child {
    justify-content: flex-end;
  }
}