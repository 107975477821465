h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  color: white;
}

h1 {
  font-family: $font-regular;
  font-size: 24px;
}

h2 {
  font-family: $font-bold;
  font-size: 22px;
}

h3 {
  font-family: $font-semibold;
  font-size: 22px;

  &.big-title {
    font-family: $font-regular;
    margin-bottom: 1rem;
  }
}

h4 {
  font-family: $font-bold;
  font-size: 18px;
}

h5 {
  font-family: $font-semibold;
  font-size: 16px;
}

h6 {
  font-family: $font-bold;
  font-size: 14px;
}

p {
  font-family: $font-regular;
  font-size: 16px;
  line-height: 24px;
  color: $blue-600;
  margin-bottom: 0;
}

h6.h7 {
  font-family: $font-semibold;
  font-size: 14px;
}

h6.h8 {
  font-family: $font-bold;
  font-size: 12px;
}

h6.h9 {
  font-family: $font-semibold;
  font-size: 10px;
}

.internal-link, .external-link {
  color: $yellow-100;
  text-decoration: underline;
  font-family: $font-regular;
  @include transition(0.3s ease-in-out all);

  &:hover {
    color: $yellow-100;
    opacity: 0.6;
  }
}

p.big-text-detailed {
  font-size: 22px;
  line-height: 33px;
  color: white;
  text-align: center;
  padding: 0 1rem;
  margin-bottom: 4rem;

  span {
    font-family: $font-bold;
  }
}

.help-row {
  display: flex;
  width: 100%;
  height: 100%;
}

p.small-title-detailed {
  font-size: 14px;
  font-family: $font-bold;
  color: white;
  opacity: 0.6;
  margin-bottom: 0.5rem;
}
