.header {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: $blue-100;
  z-index: 100;

  &.hide {
    display: none;
  }

  @media (min-width: 1024px) {
    height: 84px;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
  }
}

.brand-holder {
  display: flex;
  align-items: center;

  h6 {
    margin-bottom: 0;
  }
}

.header-right {
  display: flex;
  align-items: center;
}

.header-credit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1px;
  padding-right: 0.75rem;
  font-family: $font-semibold;
  font-size: 14px;
  line-height: 1;
  margin-right: 0.5rem;
  background-color: rgba(7, 10, 42, 0.7);

  @media (min-width: 1024px) {
    margin-right: 1rem;
  }

  svg {
    margin-right: 0.5rem;
  }

  &.r7css {
    font-size: 16px;
  }
}

.header-credit-uncredited {
  border: none;
  background-color: rgba(7, 10, 42, 0.7);
  margin-bottom: 5px;

  @media (min-width: 1024px) {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: transparent;
    margin-bottom: 0;
    margin-top: 4px;
  }
}

.uncredited-wins-desktop {
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
}

.header-avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid $yellow-100;
  padding: 0;

  img {
    width: 100%;
    height: 100%;
    @include transition(0.3s ease-in-out all);
  }

  &.inactive {
    .wrapper {
      opacity: 0;
    }

    img {
      opacity: 1;
    }
  }

  &.active {
    .wrapper {
      opacity: 1;
    }

    img {
      opacity: 0;
    }
  }
}

.header-avatar-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 0;
  width: 240px;
  padding: 1rem;
  background-color: $blue-100-opacity-90;
  border-radius: 5px;
  z-index: 4;

  @media (min-width: 1024px) {
    top: 60px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }

  .menu-buttons {
    padding: 0;
    margin-bottom: 12px;
  }
}

.arrow-up {
  position: absolute;
  top: -17px;
  right: 6px;
}

.had-holder {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  p {
    position: relative;
    font-family: $font-bold;
    font-size: 14px;
    color: white;
  }
}

.had-avatar-holder {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  margin-right: 0.5rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $yellow-100;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition(0.3s ease-in-out all);
}

.close-container {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
}

.leftright {
  height: 2px;
  width: 12px;
  position: absolute;
  background-color: $blue-100;
  border-radius: 2px;
  transform: rotate(45deg);
  @include transition(0.6s ease-in-out all);
}

.rightleft {
  height: 2px;
  width: 12px;
  position: absolute;
  background-color: $blue-100;
  border-radius: 2px;
  transform: rotate(-45deg);
  @include transition(0.6s ease-in-out all);
}

.close {
  margin: 60px 0 0 5px;
  position: absolute;
}

.close-container:active .leftright {
  transform: rotate(-45deg);
  background-color: $blue-100;
}

.close-container:active .rightleft {
  transform: rotate(45deg);
  background-color: $blue-100;
}

.close-container:active label {
  opacity: 1;
}

.fullscreen-clock {
  display: none;
  justify-content: center;
  align-items: center;
  width: 77px;
  height: 15px;
  background-color: transparent;
  color: white;
  position: absolute;
  right: 0;
  top: 1px;
  font-size: 12px;
  font-family: $font-semibold;

  &.show {
    display: flex;
  }
}