.bet-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  h4 {
    margin-right: 1rem;
  }

  &.justify-end {
    justify-content: flex-end;
  }
}

.bet-info-header-left,
.bet-info-header-right {
  display: flex;
}

.bet-info-time {
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  margin-right: 1rem;
}

.bet-info-close {
  width: 24px !important;
  height: 24px !important;
  border-radius: 24px !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  padding: 0;
  border: none;
  margin: 0;

  .leftright,
  .rightleft {
    background-color: white;
  }
}

.bet-info-close:active .leftright {
  transform: rotate(-45deg);
  background-color: white !important;
}

.bet-info-close:active .rightleft {
  transform: rotate(45deg);
  background-color: white !important;
}

.bet-info-hash {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  padding: 0.75rem 1rem;
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  margin-bottom: 1.5rem;
  word-break: break-all;
}

.bet-info-hash-input {
  min-height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  background-color: transparent;
  border-radius: 5px;
  padding: .25rem 1rem;
  font-family: $font-semibold;
  font-size: 16px;
  color: white;
  margin-bottom: 1.5rem;
  height: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: linear-gradient(to right, red 0% 135px, white 135px 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;


  p {
    overflow-y: scroll;
    overflow-wrap: normal;
    word-wrap: normal;
    margin-bottom: 0;
    color: white;
    padding-top: 1rem;

    &:focus {
      outline: none;
    }
  }

  &:focus {
    outline: none;
  }
}

.bet-info-hash-input-over {
  visibility: hidden;
  position: absolute;
  border-color: transparent;
  color: transparent;
  z-index: 99;

  &::selection {
    // color: transparent;
    // background: transparent;
  }

}

textarea.bet-info-hash-input {
  min-height: 125px;
  height: auto;
  resize: none;

  @media (min-width: 768px) {
    min-height: 150px;
  }

  @media (min-width: 1200px) {
    min-height: 100px;
  }
}

.bet-info-multiplier {
  &.mmsb-info-content {
    text-align: center;
    font-size: 18px;
    padding: .125rem .25rem;
    display: inline-flex;
    margin: 0;
  }
}

.bet-info-table {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  margin-bottom: 1.5rem;

  .bet-info-table-row {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    &:last-child {
      border-bottom: none;
    }
  }
}

.bet-info-table-row {
  display: flex;

  .bet-info-td {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
    width: 75%;

    .all-bets-td-holder {
      flex-direction: row-reverse;
      align-items: flex-start;
      margin-bottom: 0 !important;
      margin-top: 0 !important;

      .all-bets-tr-avatar {
        display: none;
      }

      .all-bets-tr-username {
        white-space: inherit;
        overflow: inherit;
        text-overflow: inherit;
        max-width: none;
      }

      .all-bets-tr-flag {
        margin-top: 4px;
      }
    }

    &:first-child {
      width: 25%;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }

    .all-bets-td-holder {
      margin-bottom: 1rem;
      margin-top: -2px;
    }

    .all-bets-tr-username {
      font-family: $font-bold;
      font-size: 12px;
      width: auto;
      max-width: none;
      color: white;
    }
  }
}

.bet-info-table-title {
  font-family: $font-bold;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 15px;
  text-transform: uppercase;
}

.bet-info-table-hash {
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  line-height: 24px;
  margin-bottom: -0.25rem;
}

.bet-info-hash-explained {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.bihe-item {
  display: flex;
  flex-direction: column;
}

.bihe-title {
  font-family: $font-bold;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 15px;
  text-transform: uppercase;
}

.bihe-content {
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  line-height: 24px;
  word-break: break-all;
}

.desktop-bet-info-button {
  .primary-button {
    height: 48px;
    font-size: 16px;
    border-radius: 24px;

    @include gradientAnimation($yellow-100-gradient,
      $yellow-200-gradient,
      0.6s,
      24px);

    &:disabled {
      opacity: 0.6;
      @include gradientAnimation($yellow-100-gradient,
        $yellow-100-gradient,
        0s,
        24px);
    }
  }
}