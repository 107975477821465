.play-now-component {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;
  background-color: rgba(7, 10, 43, 0.9);
  margin-bottom: 0.5rem;
  border-radius: 5px;

  &.button-1 {
    &.button-1-hide {
      display: none;
    }
  }
}

.betconstruct-bet-buttons {

  .play-now-component {
    background-color: rgba(237,34,139,.9);
  }
  .play-now-number-input {
    background-color: rgba(242, 97, 172, .8);
  }
  .play-now-middle-button {
    background-color: rgba(242, 97, 172, .8) !important;
  }
  .auto-cashout {
    background-color: rgba(242, 97, 172, .8);
  }
  .react-switch-bg, .react-switch{
    background: rgba(242, 97, 172, .8) !important; 
  }
  .switcher-off  {
    background: #ED228B !important;
  }

}

.play-now-top,
.play-now-bottom {
  display: flex;
  width: 100%;
}

.play-now-top {
  height: 70px;
  margin-bottom: 1rem;

  &.active {
    height: 54px;
  }
}

.play-now-bottom {
  align-items: flex-end;
  margin-top: 1rem;
  @include transition(0.3s margin-top ease-in-out);

  .react-switch {
    border: 2px solid white;
  }

  &.active,
  &.in-progress {
    @include transition(0.3s margin-top ease-in-out);
    margin-top: 0.5rem;
  }
}

.play-now-middle {
  display: flex;
  margin: 0 -0.125rem 0 -0.125rem;
  @include transition(0.3s all ease-in-out);

  &.active,
  &.in-progress {
    display: none;
  }

  .play-now-middle-button {
    border: none;
    background-color: rgba(37, 43, 99, 0.8);
    width: 100%;
    font-family: $font-regular;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    padding: 0.125rem;
    text-align: center;
    height: 24px;
    border-radius: 12px;
    line-height: 19px;

    span {
      font-size: 10px;
    }

    &:hover {
      background-color: $yellow-100-opacity-10;
    }

    &:disabled {
      opacity: 0.6;

      &:hover {
        background-color: transparent;
      }
    }

    &:focus {
      @include dropShadow(drop-shadow(0px 0px 5px #ffbf1a));
    }

    &:active {
      @include transition(0.1s ease-in-out all);
      opacity: 0.9;
    }
  }
}

.play-now-middle-button-holder {
  display: flex;
  flex: 1;
  padding: 0.125rem;
}

.auto-cashout-holder {
  flex: 1;
  min-width: 33.33%;
  margin-right: 0.5rem;
  position: relative;

  &.in-progress {
    display: none;
  }
}

.auto-cashout-holder-preview {
  flex: 1;
  margin-right: 0.5rem;
  position: relative;
  display: none;

  &.in-progress {
    display: block;
  }
}

.cancel-auto-cashout {
  position: absolute;
  background-color: transparent;
  border: none;
  top: 50%;
  transform: translate(0, -50%);
  right: 6px;
  padding: 0;
  width: 24px;
  height: 24px;

  .closed {
    .vertical {
      transition: all 0.3s ease-in-out;
      transform: rotate(-90deg);
    }

    .horizontal {
      transition: all 0.3s ease-in-out;
      transform: rotate(-90deg);
      opacity: 1;
    }
  }

  .opened {
    opacity: 1;

    .vertical {
      transition: all 0.3s ease-in-out;
      transform: rotate(-45deg);
      background-color: white !important;
    }

    .horizontal {
      transition: all 0.3s ease-in-out;
      transform: rotate(-45deg);
      background-color: white !important;
      opacity: 1;
    }

    .circle {
      background: transparent !important;
      box-shadow: none !important;
    }
  }

  .circle-plus .circle {
    @include transition(0.3s ease-in-out all);
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: linear-gradient(116.37deg, #fabf41 16.45%, #ffbf1a 96.03%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5);
    border: none;
  }

  .circle-plus .circle .horizontal {
    position: absolute;
    background-color: $blue-500;
    width: 12px;
    height: 2px;
    left: 50%;
    margin-left: -6px;
    top: 50%;
    margin-top: -1px;
  }

  .circle-plus .circle .vertical {
    position: absolute;
    background-color: $blue-500;
    width: 2px;
    height: 12px;
    left: 50%;
    margin-left: -1px;
    top: 50%;
    margin-top: -6px;
  }
}

.auto-cashout-holder,
.auto-cashout-toogle-holder,
.auto-cashout-holder-preview {
  p {
    font-family: $font-bold;
    font-size: 12px;
    line-height: 18.25px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0.25rem;
    line-height: 1.2;
  }

  &.r7css {
    p {
      font-size: 14px;
    }
  }
}

.advanced-autoplay-text {
  font-family: $font-bold;
  font-size: 12px;
  line-height: 18.25px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  margin-bottom: 0.25rem;

  span {
    font-family: $font-regular;
    font-size: 14px;
    color: white;
    padding: 0 .25rem;
  }
}

.auto-cashout-toogle-holder {
  text-align: left;
}

.auto-cashout-input-holder {
  height: 36px;
  position: relative;
}

.auto-cashout-input-holder {
  .auto-cashout {
    &:disabled {
      background-color: rgba(255, 255, 255, 0.5);
      opacity: .7;
    }
  }
}

.auto-cashout {
  height: 36px;
  width: 100%;
  background-color: rgba(37, 43, 99, 0.8);
  border-radius: 5px;
  border: none;
  font-family: $font-regular;
  font-size: 16px;
  color: white;
  padding: 0.5rem;

  &:focus {
    outline: none;
  }

  @media (max-width: 1024px) and (max-height: 767px) {
    font-size: 12px;
  }

  @media (max-width: 359px) {
    font-size: 12px;
  }
}

.play-now-number-input {
  position: relative;
  height: 100%;
  display: block;
  flex: 1;
  background-color: rgba(37, 43, 99, 0.8);
  border-radius: 5px;
  touch-action: none;
  display: flex;
  align-items: center;

  &.in-progress {
    flex: 0;
    width: 0;
    opacity: 0;
  }

  &.zoom-in {
    @media (max-width: 1023px) {zoom: 1;}
  }

  &.zoom-out {
    @media (max-width: 1023px) {zoom: 0.85;} 
  }

  input {
    flex: 1;
    width: 100%;
    background: transparent;
    border: none;
    font-family: $font-semibold;
    font-size: 22px;
    color: white;
    text-align: center;

    &:focus {
      outline: none;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.play-now-number-input-increse,
.play-now-number-input-decrese {
  background-color: rgba(255, 255, 255, 0.1);
  width: 38px;
  height: 38px;
  border-radius: 38px;
  border: 2px solid white;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @include transition(0.3s ease-in-out all);

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &:disabled {
    opacity: 0.6;

    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 0.1);

      .minus {
        &:after {
          background-color: white;
        }
      }
    }

    &:active {
      .plus {

        &:after,
        &:before {
          background-color: white;
        }
      }
    }
  }

  &:active {
    .minus {
      &:after {
        @include transition(0.1s ease-in-out all);
        background-color: $yellow-100;
      }
    }

    .plus {

      &:after,
      &:before {
        @include transition(0.1s ease-in-out all);
        background-color: $yellow-100;
      }
    }
  }

  .minus {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -1px;
      left: 50%;
      margin-left: -9px;
      width: 18px;
      height: 2px;
      background-color: white;
    }
  }

  .plus {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -1px;
      left: 50%;
      margin-left: -9px;
      width: 18px;
      height: 2px;
      background-color: white;
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -9px;
      left: 50%;
      margin-left: -1px;
      width: 2px;
      height: 18px;
      background-color: white;
    }
  }
}

.closed {
  .vertical {
    transition: all 0.3s ease-in-out;
    transform: rotate(-90deg);
  }

  .horizontal {
    transition: all 0.3s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
  }
}

.opened {
  .vertical {
    transition: all 0.3s ease-in-out;
    transform: rotate(90deg);
  }

  .horizontal {
    transition: all 0.3s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
  }
}

.circle-plus .circle {
  @include transition(0.3s ease-in-out all);
  position: relative;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  border: solid 2px $blue-500;
}

.circle-plus .circle .horizontal {
  position: absolute;
  background-color: $blue-500;
  width: 6px;
  height: 2px;
  left: 50%;
  margin-left: -3px;
  top: 50%;
  margin-top: -1px;
}

.circle-plus .circle .vertical {
  position: absolute;
  background-color: $blue-500;
  width: 2px;
  height: 6px;
  left: 50%;
  margin-left: -1px;
  top: 50%;
  margin-top: -3px;
}

.bet-button {
  flex: 1;
  max-width: 130px;
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  width: 100%;
  border-radius: 5px;
  background: $yellow-100-gradient;
  font-family: $font-bold;
  font-size: 22px;
  line-height: 1;
  color: $blue-500;
  padding: 0 1rem;
  @include boxShadow(0px 0px 10px rgba(0, 0, 0, 0.4));
  @include gradientAnimation($yellow-100-gradient,
    $yellow-200-gradient,
    0.6s,
    5px);

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

  &:disabled {
    opacity: 0.6;
    @include gradientAnimation($yellow-100-gradient,
      $yellow-100-gradient,
      0s,
      5px);
  }

  &:focus {
    @include boxShadow(0px 0px 15px rgba(255, 191, 26, 0.8));
  }

  &:active {
    @include transition(0.1s ease-in-out all);
    background-color: $yellow-300-gradient;
  }

  &.bet-button-cancel {
    color: white;
    background: linear-gradient(106.95deg, #9665e5 5.14%, #633afa 73.93%);
    @include boxShadow(0px 0px 10px rgba(99, 58, 250, 0.8));
    @include gradientAnimation(linear-gradient(106.95deg, #9665e5 5.14%, #633afa 73.93%),
      linear-gradient(106.95deg, #9665e5 5.14%, #481cec 73.93%),
      0.6s,
      5px);

    &:focus {
      @include boxShadow(0px 0px 10px #633afa);
    }

    &:disabled {
      opacity: 0.6;
      @include gradientAnimation(linear-gradient(106.95deg, #9665e5 5.14%, #633afa 73.93%),
        linear-gradient(106.95deg, #9665e5 5.14%, #633afa 73.93%),
        0s,
        5px);
    }

    &:active {
      @include transition(0.1s ease-in-out all);
      background: linear-gradient(106.95deg, #9665e5 5.14%, #481cec 73.93%);
    }

    .circle-plus .circle {
      border-color: white;
    }

    .circle-plus .circle .vertical {
      background-color: white;
    }
  }

  &.bet-button-in-progress {
    max-width: 100%;
    margin-left: 0;
    color: white;
    background: linear-gradient(304.41deg, #06d6a0 18.95%, #a7e623 110.94%);
    @include boxShadow(0px 4px 7px rgba(0, 0, 0, 0.8));
    @include gradientAnimationBetButton(linear-gradient(304.41deg, #06d6a0 18.95%, #a7e623 110.94%),
      linear-gradient(304.41deg, #06d6a0 18.95%, #a7e623 110.94%),
      0.6s,
      5px);

    &:focus {
      @include boxShadow(0px 4px 7px rgba(0, 0, 0, 1));
    }

    &:disabled {
      opacity: 0.6;
      @include gradientAnimationBetButton(linear-gradient(304.41deg, #06d6a0 18.95%, #a7e623 110.94%),
        linear-gradient(304.41deg, #06d6a0 18.95%, #a7e623 110.94%),
        0s,
        5px);
    }

    &:active {
      @include transition(0.1s ease-in-out all);
      background: linear-gradient(304.41deg, #06d6a0 18.95%, #a7e623 110.94%);
    }

    .circle-plus .circle {
      border-color: white;
    }

    .circle-plus .circle .vertical {
      background-color: white;
    }
  }
}

.cashout-button-holder {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.cashout-button-odometer {
  display: flex;
  margin-left: 2rem;
  margin-top: 1px;
  z-index: -1 !important;
  position: relative;

  .odometer-formatting-mark {
    display: inline-block;
    vertical-align: middle;
  }
}

.wftnr-holder {
  position: absolute;
  top: -50px;
  min-width: 250px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &.next-round-loader {
    position: relative;
    top: inherit;
  }
}

.wftnr-line {
  width: 2px;
  height: 10px;
  background: rgba(7, 10, 43, 0.9);
}

.wftnr-text {
  padding: 0.5rem 1rem;
  min-width: 255px;
  height: 40px;
  border-radius: 20px;
  background: rgba(7, 10, 43, 0.9);
  @include boxShadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 21.29px;

  p {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: $font-semibold;
    color: white;
  }

  &.r7css {
    p {
      font-size: 16px;
    }
  }
}

.cashout-header-dialog-holder {
  position: absolute;
  left: 0;
  right: 0;
}

.cashout-header-dialog {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-top: 1px solid rgba(81, 83, 106, 0.4);
  background: linear-gradient(21.41deg,
      rgba(167, 230, 35, 0.2) -20.84%,
      rgba(167, 230, 35, 0) 27.92%),
    rgba(7, 10, 43, 0.8);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: white;
  font-family: $font-regular;
  font-size: 14px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: linear-gradient(5deg,
        rgba(6, 214, 160, 1) 0%,
        transparent 25%,
        transparent 100%);
    // animation: rotate 4s linear infinite;
  }

  &.warning {
    &:before {
      background: linear-gradient(5deg,
          rgba(219, 31, 53, 1) 0%,
          transparent 25%,
          transparent 100%);
    }
  }
}

.chat-header-dialog {
  position: relative;
  background: linear-gradient(116.41deg, #db1f35 33.41%, #ffbf1a 212.04%);
  margin-top: -16px;

  @media (min-width: 1024px) {
  position: absolute;
  margin-top: 0;
  left: 0;
  right: 0;
  }
}

.cashout-header-dialog-amount {
  font-family: $font-bold;
  text-decoration: underline;
  color: #06d6a0;
}

.cashout-header-dialog-button {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #070a2b;
  border: none;
  padding: 0;
  color: white;
  margin: 0;
}

.header-bonus-games {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  top: 40px;
  left: 0;
  right: 0;

  @media (min-width: 1024px) {
    display: none;
  }

  &.no-header {
    top: -20px;
  }

  &.header-bonus-games-tournament-no-header {
    top: 30px;
  }
}

.header-bonus-games-tournament {
  top: 90px;
}


.header-bonus-games-desktop {
  position: relative;
  top: initial;
  left: initial;
  right: initial;
}

.header-bonus-games-item {
  margin: 0 1px;
  background: rgba(7, 10, 42, 0.7);
  padding: 2px;
  padding-right: 0.75rem;
  height: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }
}

.header-bonus-games-value {
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  line-height: 24px;
  margin-left: 0.5rem;

  span {
    font-family: $font-bold;
    color: #06d6a0;
  }

  .header-bonus-games-currency {
    font-size: 10px;
    color: white;

    &.r7css {
      font-size: 12px;
    }
  }

  &.r7css {
    font-size: 16px;
  }
}

.header-bonus-games-start-button {
  width: 45px;
  height: 20px;
  background: linear-gradient(287.74deg, #06d6a0 4.68%, #a7e623 117.98%);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.74);
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  border-radius: 10px;
  border: none;
  padding: 0;
  line-height: 20px;

  &.waiting {
    opacity: 0.5;
  }
}

.advanced-autoplay-button {
  flex: 1;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  width: 100%;
  border-radius: 5px;
  background: $yellow-100-gradient;
  font-family: $font-bold;
  font-size: 12px;
  line-height: 1;
  color: $blue-500;
  padding: 0 1rem;
  margin-right: 0.5rem;
  @include boxShadow(0px 0px 10px rgba(0, 0, 0, 0.4));
  @include gradientAnimation($yellow-100-gradient,
    $yellow-200-gradient,
    0.6s,
    5px);

  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

  &:disabled {
    opacity: 0.6;
    @include gradientAnimation($yellow-100-gradient,
      $yellow-100-gradient,
      0s,
      5px);
  }

  &:focus {
    @include boxShadow(0px 0px 15px rgba(255, 191, 26, 0.8));
  }

  &:active {
    @include transition(0.1s ease-in-out all);
    background-color: $yellow-300-gradient;
  }

  &.in-progress {
    display: none;
  }

  &.highlighted {
    position: relative;
    z-index: 1052;
  }

  &.stop-autoplay {
    order: 1;
    color: white;
    background: linear-gradient(106.95deg, #9665e5 5.14%, #633afa 73.93%);
    @include boxShadow(0px 0px 10px rgba(99, 58, 250, 0.8));
    @include gradientAnimation(
      linear-gradient(106.95deg, #9665e5 5.14%, #633afa 73.93%),
      linear-gradient(106.95deg, #9665e5 5.14%, #481cec 73.93%),
      0.6s,
      5px
    );

    &:focus {
      @include boxShadow(0px 0px 10px #633afa);
    }
    &:disabled {
      opacity: 0.6;
      @include gradientAnimation(
        linear-gradient(106.95deg, #9665e5 5.14%, #633afa 73.93%),
        linear-gradient(106.95deg, #9665e5 5.14%, #633afa 73.93%),
        0s,
        5px
      );
    }

    &:active {
      @include transition(0.1s ease-in-out all);
      background: linear-gradient(106.95deg, #9665e5 5.14%, #481cec 73.93%);
    }

    .circle-plus .circle {
      border-color: white;
    }

    .circle-plus .circle .vertical {
      background-color: white;
    }
  }

  &.r7css {
    font-size: 14px;
  }
}

.aap-close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.aap-arrow {
  position: absolute;
  top: -90px;
  z-index: 1;
  left: 50%;
  margin-left: -45px;
}