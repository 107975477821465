.avatars-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .simplebar-content {
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: 1024px) {
    height: calc(100vh - 339px);
    padding-bottom: 2.25rem;

    .simplebar-scrollbar {
      right: 4px;
      left: 0;
    }

    .simplebar-track.simplebar-vertical {
      top: 2px;
      bottom: 2px;
      right: -16px;
      width: 12px;
      border-radius: 6px;
    }

    .simplebar-scrollbar:before {
      background: #636479;
    }
  }
}

.avatar-container {
  border: 2px solid $blue-300;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  width: 25%;
  background-color: transparent;
  position: relative;
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 12.5%;
  }

  @media (min-width: 1024px) {
    border: 2px solid $blue-400;
  }

  &:hover {
    background: rgba(255, 191, 26, 0.15);
    @include transition(0.3s ease-in-out all);
  }

  &:disabled {
    opacity: 0.6;

    &:hover {
      background-color: transparent;
    }
  }

  &.avatar-active {
    background: rgba(255, 191, 26, 0.15);
    border: 2px solid $yellow-100;
    @include transition(0.3s ease-in-out all);

    .avatar-image {
      @include dropShadow(drop-shadow(0px 2px 10px #070b2f));
    }

    .avatar-selected {
      @include transition(0.3s ease-in-out opacity);
      opacity: 1;
    }

    &:disabled {
      border: 2px solid $blue-300;
      background-color: transparent;

      .avatar-selected {
        opacity: 0;
      }
    }
  }
}

.avatar-selected {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: $yellow-100;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 5px;
}

.avatar-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 0;
}

.avatar-holder-vip {
  position: relative;
}

.vip-avatar-marker {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  z-index: 1;
}

.desktop-username {
  .header-vip-avatar-marker {
    top: 5px;
  }
}

.header-vip-avatar-marker {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 4px;
  right: -16px;
}

.table-vip-avatar-marker {
  width: 10px;
  height: 10px;
  margin-left: 2px;
  margin-top: -2px;
}

.message-vip-avatar-marker {
  width: 12px;
  height: 12px;
  position: relative;
  top: -1px;
  right: -5px;
}

.avatar-image {
  width: 100%;
  margin-bottom: 0.5rem;
}

.avatar-title {
  font-family: $font-bold;
  font-size: 14px;
  color: white;
  line-height: 21.29px;
}