.avatar-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  background-color: transparent;
  color: $yellow-100;
  border: 2px solid;
  border-color: $yellow-100;
  font-family: $font-bold;
  font-size: 14px;
  color: $yellow-100;
  line-height: 1;
  border-radius: 18px;
  @include transition(0.3s ease-in-out all);

  &:hover {
    background-color: $yellow-100-opacity-10;
  }
  &:disabled {
    opacity: 0.6;
    &:hover {
      background-color: transparent;
    }
  }
  &:focus {
    @include dropShadow(drop-shadow(0px 0px 5px #ffbf1a));
  }
  &:active {
    @include transition(0.1s ease-in-out all);
    opacity: 0.9;
  }
}

.menu-item-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  background-color: transparent;
  @include transition(0.3s ease-in-out all);

  &:hover {
    background-color: $white-opacity-10;
  }
  &:disabled {
    opacity: 0.6;
    &:hover {
      background-color: transparent;
    }
  }
  &:focus {
    border: 1px solid $yellow-100;
  }
  &:active {
    @include transition(0.1s ease-in-out all);
    background-color: $yellow-100;
  }
}

.primary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  width: 100%;
  height: 36px;
  border-radius: 18px;
  background: $yellow-100-gradient;
  font-family: $font-bold;
  font-size: 14px;
  line-height: 1;
  color: $blue-500;
  padding: 0 1rem;
  @include boxShadow(0px 0px 10px rgba(0, 0, 0, 0.4));
  @include gradientAnimation(
    $yellow-100-gradient,
    $yellow-200-gradient,
    0.6s,
    18px
  );

  &.r7css {
    font-size: 16px;
  }

  &:disabled {
    opacity: 0.6;
    @include gradientAnimation(
      $yellow-100-gradient,
      $yellow-100-gradient,
      0s,
      18px
    );
  }
  &:focus {
    @include boxShadow(0px 0px 15px rgba(255, 191, 26, 0.8));
  }
  &:active {
    @include transition(0.1s ease-in-out all);
    background-color: $yellow-300-gradient;
  }

  &.button-remove-bet {
    color: white;
    background: linear-gradient(106.95deg, #9665e5 5.14%, #633afa 73.93%);
    @include boxShadow(0px 0px 10px rgba(99, 58, 250, 0.8));
    @include gradientAnimation(
      linear-gradient(106.95deg, #9665e5 5.14%, #633afa 73.93%),
      linear-gradient(106.95deg, #9665e5 5.14%, #481cec 73.93%),
      0.6s,
      18px
    );

    &:focus {
      @include boxShadow(0px 0px 10px #633afa);
    }
    &:disabled {
      opacity: 0.6;
      @include gradientAnimation(
        linear-gradient(106.95deg, #9665e5 5.14%, #633afa 73.93%),
        linear-gradient(106.95deg, #9665e5 5.14%, #633afa 73.93%),
        0s,
        18px
      );
    }

    &:active {
      @include transition(0.1s ease-in-out all);
      background: linear-gradient(106.95deg, #9665e5 5.14%, #481cec 73.93%);
    }

    .circle-plus .circle {
      border-color: white;
    }

    .circle-plus .circle .vertical {
      background-color: white;
    }
  }
}

.secondary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-color: $yellow-100;
  padding: 0;
  width: 100%;
  height: 36px;
  border-radius: 18px;
  background-color: transparent;
  font-family: $font-bold;
  font-size: 14px;
  line-height: 1;
  color: $yellow-100;
  padding: 0 1rem;
  @include boxShadow(0px 0px 10px rgba(0, 0, 0, 0.4));
  @include transition(0.3s ease-in-out all);

  &.r7css {
    font-size: 16px;
  }

  &:hover {
    background-color: $yellow-100-opacity-10;
  }
  &:disabled {
    opacity: 0.6;
    &:hover {
      background-color: transparent;
    }
  }
  &:focus {
    @include dropShadow(drop-shadow(0px 0px 5px #ffbf1a));
  }
  &:active {
    @include transition(0.1s ease-in-out all);
    opacity: 0.9;
  }
}

.chat-button {
  display: flex;
  background-color: transparent;
  border: none;
  padding: 0;
  opacity: 0.5;
  @include transition(0.3s ease-in-out all);
  &:hover {
    opacity: 1;
  }
  &:disabled {
    opacity: 0.2;
    &:hover {
      opacity: 0.2;
    }
  }
  &:focus {
    opacity: 0.5;
    svg {
      path,
      g {
        fill: $yellow-100;
      }
    }
  }
  &:active {
    @include transition(0.1s ease-in-out all);
    svg {
      path,
      g {
        fill: $yellow-100;
      }
    }
  }
}

.odometer-currency {
  margin-left: 5px;
  font-size: 20px;
  margin-top: 3px;
}