$font-regular: "TitilliumWeb-Regular", Roboto-Regular;
$font-semibold: "TitilliumWeb-SemiBold", Roboto-Medium;
$font-bold: "TitilliumWeb-Bold", Roboto-Bold;

$blue-100: #070a2b; // header background color
$blue-200: #030520; // bottom navigator background color
$blue-300: #0e1340; // body background color
$blue-400: #070a2a; // menu item background color
$blue-500: #141945; // menu button text color
$blue-600: #7c84cb; // paragraph text color
$blue-700: #252b63; // chat background color
$blue-800: #3BC4F2; // spam filter text color
$blue-900: #1C1E3B; // spam filter progress bar background color
$blue-1000: #3C4384; // spam filter progress bar background color
$blue-1100: #191F52; 
$blue-1200: #20244A; // time frame filter hover 
$blue-100-opacity-90: rgba(7, 10, 43, 0.9); // dropdown menu background color

$red-100: #DB1F35;

$green-100: #A7E623;
$green-200: #466408;

$yellow-100: #ffbf19; // button text and border color
$yellow-200: #FFBF1A; 
$yellow-100-opacity-10: rgba(
  255,
  191,
  26,
  0.1
); // button hover background color

$gray-100: #454b7a; // border right menu item color

$yellow-100-gradient: linear-gradient(
  116.37deg,
  #fabf41 16.45%,
  #ffbf1a 96.03%
); // button background
$yellow-200-gradient: linear-gradient(
  116.37deg,
  #fabf41 16.45%,
  #c99308 96.03%
); // button hover background
$yellow-300-gradient: linear-gradient(
  116.37deg,
  #fabf41 16.45%,
  #ffbf1a 96.03%
); // button active background

$white-opacity-10: rgba(255, 255, 255, 0.1); // arrow button hover opacity

$step-1-color: #4089f8;
$step-2-color: #633afa;
$step-3-color: #1dbe5e;
$step-1-gradient-color: radial-gradient(
  ellipse farthest-corner at 0% 50%,
  #4089f8 0%,
  rgba(64, 137, 248, 0) 100%
);
$step-2-gradient-color: radial-gradient(
  ellipse farthest-corner at 0% 50%,
  #633afa 0%,
  rgba(99, 58, 250, 0) 100%
);
$step-3-gradient-color: radial-gradient(
  ellipse farthest-corner at 0% 50%,
  #1dbe5e 0%,
  rgba(29, 190, 94, 0) 100%
);

$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;
$xx-large: 1400px;
